@import "../../styles.css";

.buildcard {
    max-width: 282px;
    height: 99px;
    margin: 0 auto;
    background-color: var(--quintenary);
    color: var(--secondary);
    transform: skew(15deg, 0deg);
    border-radius: 10px;
    display: flex;
    z-index: 0;
}

.buildcard-presentation {
    width: 282px;
    height: 99px;
    background-color: var(--quaternary);
    color: var(--secondary);
    transform: skew(15deg, 0deg) translateY(-149px) translateX(109px);
    border-radius: 10px;
    position: absolute;
    z-index: -1;
}


.buildcard > * {
    transform: skew(-15deg, 0deg);
}

.buildcard > img {
    margin-left: 10px;
}

.buildcard > aside {
    flex-grow: 3;
}

.buildcard-aside-title-container {
    padding-left: 20px;
    margin-top: 10px;
    font-size: 16px;
}

.buildcard-aside-likes-container {
    margin-top: -15px;
    display: flex;
    width: 60%;
    margin: -7px auto;
}

.buildcard-aside-likes-container > * {
    flex-grow: 2;
    text-align: center;
}

.buildcard-aside-comments-container {
    text-align: center;
    margin-top: 14px;
}