.greeting {
    font-size: 1.15em;
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.selects {
    margin-top: 2px;
}

.builds {
    margin-top: 20px;
    height: 20vh;
}

.builds > * > * {
    margin-top: 40px;
}