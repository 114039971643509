@import url('https://fonts.googleapis.com/css?family=Inter');

:root {
  --primary: #30C5FF;
  --secondary: #2A2D34;
  --tertiary: #5C946E;
  --quaternary: #80C2AF;
  --quintenary: #A0DDE6;
}

body {
  padding: 0;
  margin: 0;
  background: var(--secondary);
  color: var(--quintenary);
  overflow: hidden;
}