:root {
    --primary: #30C5FF;
    --secondary: #2A2D34;
    --tertiary: #5C946E;
    --quaternary: #80C2AF;
    --quintenary: #A0DDE6;
  }

nav {
    width: 100%;
    height: calc(0.05831533477 * 100vh);
    background:var(--primary);
    color: var(--secondary);
    border-bottom: 8px solid var(--quaternary);
    display: flex;
    font-family: helvetica;
    align-items: center;
}

nav h1 {
    padding-left: 16px;
    font-weight: 200;
}

nav section {
    margin-left: auto;
    padding-right: 14px;
}

.title {
    text-decoration: none;
    color: var(--secondary);
}

.link {
    background: transparent;
    border: 0;
    height: 40px;
    padding: 10px;
    color: var(--secondary);
    text-decoration: none;
}

.link:active {
    color: pink;
}

.neutralState {
    font-weight: lighter;
    text-decoration: none;
}

selectedState {
    font-weight: bolder;
    text-decoration: underline;
}

.navImg {
    width: 100px;
    margin-left: 20px;
    padding-bottom: 6px;
    padding-right: 15px;
}



@media only screen and (max-width: 768px) {
    nav {
        font-size: 12px;
    }
}