@import "../../styles.css";

:root {
    --square-size: 50px;
    --square-border: 1px solid black;
    --square-position: absolute;
    --square-combined-color: var(--quaternary);
    --square-one-color: var(--primary);
    --square-two-color: var(--quintenary); 
    
    --animation-speed: 1.5s;
    
    --starting-position: translate(-50%, -50%);
    --bottom-center-position: translate(-50%, 0%);
    --bottom-right-position: translate(0%, 0%);
    --top-right-position: translate(0%, -100%);
    --top-center-position: translate(-50%, -100%);
    --top-left-position: translate(-100%, -100%);
    --bottom-left-position: translate(-100%, 0%);
    --absolute-position: 50%;
  }

  @keyframes animation-one {
    0% { background: var(--square-one-color); }
    
    20% {
      transform: var(--bottom-center-position);
      background: var(--square-one-color);
    }
    
    40% {
      transform: var(--bottom-right-position);
      background: var(--square-one-color);
    }
    
    60% {
      transform: var(--top-right-position);
      background: var(--square-one-color);
    }
    
    80% {
      transform: var(--top-center-position);
      background: var(--square-one-color);
    }
    
    100% {
      background: var(--square-combined-color);
      transform: var(--starting-position);
    }
    
  }
  
  @keyframes animation-two {
    0% { background: var(--square-two-color); }
    
    20% {
      transform: var(--top-center-position);
      background: var(--square-two-color);
    }
    
    40% {
      transform: var(--top-left-position);
      background: var(--square-two-color);
    }
    
    60% {
      transform: var(--bottom-left-position);
      background: var(--square-two-color);
    }
    80% {
      transform: var(--bottom-center-position);
      background: var(--square-two-color);
    }
    100% {
      transform: var(--starting-position);
      background: var(--square-combined-color);
    }
  }
  
  
  .square {
    width: var(--square-size);
    height: var(--square-size);
    position: var(--square-position);
    left: var(--absolute-position);
    top: var(--absolute-position);
    transform: var(--starting-position);
  }
  
  .square-one {
    background: var(--square-one-color);
    animation: animation-one var(--animation-speed) infinite;
  }
  
  .square-two {
    background: var(--square-two-color);
    animation: animation-two var(--animation-speed) infinite;
  }