@import "../../styles.css";

.ggselect {
    border: 3px solid  var(--tertiary);
    color: var(--tertiary);
    border-radius: 8px;
    padding-top: 9px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    height: 28px;
    width: 66.6%;
}

.ggselect:hover {
    background: radial-gradient(var(--secondary), var(--secondary-highlight));
}

.ggselect-icon {
    float: right;
    padding-right: 21.5px;
}

.ggoptions {
    border: 3px solid  var(--tertiary);
    width: 66.6%;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    max-height: 300px;
    overflow: scroll;
    background: var(--secondary)
}

.ggoptions ul {
    list-style: none;
    padding: 0;
    text-align: center;
}

.ggoptions-listbox {
    height: 20px;
    width: 100%;
    background: var(--secondary);
    padding: 12px 0;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
}

.ggoptions-listbox:hover {
    background: var(--quintenary);
    color: var(--secondary)
}
