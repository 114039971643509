@import "../../styles.css";

.info {
    width: 95%;
    height: 80vh; /** tested, works for now. */
    margin: 1.5vh auto;
    font-family: 'Inter';
}

.info > * { 
    width: 100%; 
}

.overview {
    height: 20%;
    display: flex;
}

.composition {
    width: 100%;
    height: 35%;
    margin-top: 7%;
    display: flex;
}

.stats {
    border: 1px solid var(--quaternary);
    width: 25%;
    display: flex;
    flex-direction: column;
}

.stats:first-child {
    padding-top: 2%;
}

.stats > * {
    text-align: center;
    flex-grow: 2;
}

.stats-label, .stats-value {
    margin: 0;
}

.stats-label { color: var(--primary)}
.stats-value { color: val}

.itemlist {
    width: 73%;
    border: 1px solid var(--quaternary);
    margin-left: 2%;
    overflow: scroll;
}

.item {
    width: 20%;
    margin: 0;
    padding: 0;
}

.details {
    margin-top:3.33%;
    height: 25%;
    display: flex;
}

.effects, .radar { width: 50%; }

.effects {
    border: 1px solid var(--quintenary);
    height: 100%;
}

.radar {
    border: 1px solid var(--quintenary);
    height: 100%;
    margin-left: 2vw;
}

.controls {
    margin-top: 3.33%;
    height: 10%;
}

.view-comments,
.back {
    width: 100%;
    height: 48%;
    background: transparent;
    font-size: 16px;
    font-weight: bold;
}

.view-comments {
    border: 3px solid var(--quintenary);
    color: var(--quintenary);
}

.back {
    margin-top: 2%;
    background: var(--quintenary);
    color: var(--secondary)
}

.left-panel > p { 
    margin-top: 0; 
    font-size: 12px;
    text-align: center;
}

.left-panel {
    width: 40%;
}

.right-panel {
    text-align: center;
    font-size: 12px;
    width: 60%;
}

.right-panel > h1 { color: var(--primary); margin-top: 0; }
.right-panel > p { color: var(--quintenary)}

.buildinfo-vote-buttons > * {
    width: 50%;
    min-height: 30px;
    font-size: 10px;
    background: var(--quintenary);
}

.buildinfo-vote-buttons > .likes {
    --border: 10px;
    border-top-left-radius: var(--border);
    border-bottom-left-radius: var(--border);
}

.buildinfo-vote-buttons > .dislikes {
    --border: 10px;
    border-top-right-radius: var(--border);
    border-bottom-right-radius: var(--border);
}

.buildinfo-character { 
    width: auto;
    height:70px;  
    margin-left: 50%;
    transform: translateX(-50%); 
}

